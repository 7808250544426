import { toast } from 'react-toastify';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { commify, formatUnits, parseEther } from '@ethersproject/units';

import { ITier } from '@src/ts/interfaces';
import { NetworkType } from '@src/ts/types';

// TODO: when setting a new DEFAULT_CHAIN we need to add it here
const BSC_PRFIXES = {
    56: '',
    97: 'testnet.',
    59144: '',
    59140: 'testnet.',
};

export const bnMin = (bns: BigNumber[]): BigNumber => {
    if (bns.length > 0) {
        let min = bns[0];

        bns.forEach((bn) => {
            if (bn.lt(min)) {
                min = bn;
            }
        });
        return min;
    }
    return null;
};

export const bnSum = (bns: BigNumber[]): BigNumber =>
    bns.reduce((acc, curr) => acc.add(curr), BigNumber.from(0));

const network_names = {
    ethereum: 'Ethereum',
    bsc: 'BSC',
    polygon: 'Polygon',
};

export const getNetworkName = (type: NetworkType): string | string[] => {
    return network_names[type];
};

export function shortenHex(hex: string, length = 4): string {
    return `${hex.substring(0, length + 2)}…${hex.substring(
        hex.length - length,
    )}`;
}

/**
 *
 * @param {("Account"|"Transaction")} type
 * @param {[number, string]} data
 */
export function formatChainScanLink(
    type: 'Account' | 'Transaction',
    data: [number, string],
): string {
    const [chain_id, address] = data;
    switch (type) {
        case 'Account': {
            return `https://${BSC_PRFIXES[chain_id]}bscscan.com/address/${address}`;
        }
        case 'Transaction': {
            return `https://${BSC_PRFIXES[chain_id]}bscscan.com/tx/${address}`;
        }
    }
}

export const removeCommas = (s: string): string =>
    s
        .split('')
        .filter((c) => c !== ',')
        .join('');

/**
 * @name parseBalance
 *
 * @param {import("@ethersproject/bignumber").BigNumberish} balance
 * @param {number} decimals
 * @param {number} decimalsToDisplay
 *
 * @returns {string}
 */
export const parseBalance = (
    balance: BigNumberish,
    decimals = 18,
    decimalsToDisplay = 2,
    round_up = false,
): string => {
    return commify(
        round_up
            ? roundUp(
                  Number(formatUnits(balance, decimals)),
                  decimalsToDisplay,
              ).toFixed(decimalsToDisplay)
            : roundDown(
                  Number(formatUnits(balance, decimals)),
                  decimalsToDisplay,
              ).toFixed(decimalsToDisplay),
    );
};

export function roundDown(x: number, decimals: number) {
    return Math.floor(x * 10 ** decimals) / 10 ** decimals;
}

export function roundUp(x: number, decimals: number) {
    return Math.ceil(x * 10 ** decimals) / 10 ** decimals;
}

export const parseWei = (balance: string): BigNumber =>
    parseEther(removeCommas(balance));

export const executeTx = async (
    // eslint-disable-next-line
    promise: Promise<any>,
    message: string,
    callback?: () => Promise<void>,
): Promise<string> => {
    try {
        toast.info('Awaiting successful transaction');
        const tx = await promise;
        const result = await tx.wait();

        if (result.status === 0) {
            throw new Error('Transaction failed');
        }

        toast.success(`${message || 'Success'}`);
        if (callback) await callback();

        return tx.hash;
    } catch (err) {
        toast.error(err.reason || err.message);
    }
    return '';
};

export const getTierFromStake = (amount: string, tiers: ITier[]) => {
    let tier = -1;

    for (let i = 0; i < tiers.length; i++) {
        if (BigNumber.from(amount).gte(tiers[i].min_limit)) {
            tier = i;
        }
    }
    return tier;
};
