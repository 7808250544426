import { api_client } from '@src/bootstrap/index';
import { CONTENT_API } from '@src/services/content';
import { PROJECT } from '@src/services/project';
import { ContentSectionType } from '@src/ts/constants';
import { IContentSection, NotificationData } from '@src/ts/interfaces';

export const getNotificationData = async (): Promise<NotificationData> => {
    const { getNotification } = await api_client.query<{
        getNotification: NotificationData;
    }>({
        query: PROJECT.GET_NOTIFICATION_DATA,
        fetchPolicy: 'network-only',
    });

    return getNotification;
};

export const getPlatformImages = async () => {
    const images = await api_client.query({
        query: PROJECT.GET_PLATFORM_IMAGES,
    });
    return images['retrieveImages'];
};
export const getContentSection = async (section: ContentSectionType) => {
    const { retrieveContentSection } = await api_client.query<{
        retrieveContentSection: IContentSection;
    }>({
        query: CONTENT_API.RETRIEVE_CONTENT_SECTION,
        fetchPolicy: 'network-only',
        variables: {
            name: section,
        },
    });
    return retrieveContentSection;
};
